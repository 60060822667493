.table {
  border-collapse: collapse;
  width: 100%;

  border-color: var(--accentColor);
}

.table th {
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: .2rem;
}

.table td {
  border-color: var(--disabled);
  border-width: 0.1rem;
  border-style: solid;
  padding: 0.5rem;
}

@each $color in black, white, cyan, green, orange, pink, purple, red, yellow {

  .table.table-$(color) td {
    border-color: var(--$(color));
  }

  .table-striped.table-$(color) tr:nth-child(even) {
    background-color: var(--$(color)Light);
  }
}

.table.table-striped tr:nth-child(even) {
  background-color: var(--whiteLight);
}
