undefined {

  .drac-text-$(from)-$(to) {
    background-image: linear-gradient(
      var(--gradientDegree),
      var(--$(from)) 0%,
      var(--$(to)) 100%
    );

    background-size: 100%;
    background-clip: text;

    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }
}