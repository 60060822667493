.card {
  border-radius: var(--rounded-lg);
  box-shadow: -2px var(--glow-spacing-x) var(--glow-spacing-y) var(--glowColor);
  background-color: var(--accentColor);
}

.card-subtle {
  box-shadow: none;
  background: none;
  border-width: 2px;
  border-style: solid;
}
