undefined {

  .drac-scrollbar-$(color)::-webkit-scrollbar-thumb {
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 1rem;
    border: 0.4rem solid transparent;
    background-clip: content-box;
    background-color: var(--drac-scrollbar-thumb);
    -moz-background-clip: content;
    -webkit-background-clip: content;
  }
}