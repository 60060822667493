:root {
  --border-size: 0.1rem;
  --rounded-none: 0;
  --rounded-sm: 0.125rem;
  --rounded: 0.25rem;
  --rounded-md: 0.375rem;
  --rounded-lg: 0.5rem;
  --rounded-xl: 0.75rem;
  --rounded-2xl: 1rem;
  --rounded-3xl: 1.5rem;
  --rounded-full: 9999px;
}

@each $color in black, white, cyan, green, orange, pink, purple, red, yellow {
  .border-$(color) {
    border-color: var(--$(color));
  }
}

.rounded-none {
  border-radius: var(--rounded-none);
}

.rounded-sm {
  border-radius: var(--rounded-sm);
}

.rounded {
  border-radius: var(--rounded);
}

.rounded-md {
  border-radius: var(--rounded-md);
}

.rounded-lg {
  border-radius: var(--rounded-lg);
}

.rounded-xl {
  border-radius: var(--rounded-xl);
}

.rounded-2xl {
  border-radius: var(--rounded-2xl);
}

.rounded-3xl {
  border-radius: var(--rounded-3xl);
}

.rounded-full {
  border-radius: var(--rounded-full);
}