.checkbox,
.radio {
  --active-inner: var(--white);
  --background: var(--white);
  --disabled: var(--blackSecondary);
  --disabled-inner: var(--blackTernary);
  --local-bg-color: var(--border-hover);
  appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--local-bg-color, var(--border));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}

.checkbox:after,
.radio:after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: transform var(--local-transform-speed, 0.3s)
      var(--local-transform-effect, ease),
    opacity var(--local-opacity-speed, 0.2s);
}

.checkbox:checked {
  background: var(--local-bg, var(--background));
}

.checkbox:checked,
.radio:checked {
  --local-bg: var(--active);
  --local-bg-color: var(--active);
  --local-opacity-speed: 0.3s;
  --local-transform-speed: 0.6s;
  --local-transform-effect: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.checkbox:disabled,
.radio:disabled {
  --local-bg: var(--disabled);
  cursor: not-allowed;
  opacity: 0.9;
}

.checkbox:disabled:checked,
.radio:disabled:checked {
  --local-bg: var(--disabled-inner);
  --local-bg-color: var(--border);
}

.checkbox:disabled + label,
.radio:disabled + label {
  cursor: not-allowed;
}

.checkbox:focus,
.radio:focus {
  box-shadow: 0 0 0 var(--focus);
}

.checkbox:not(.switch),
.radio:not(.switch) {
  width: 21px;
}

.checkbox:not(.switch):after,
.radio:not(.switch):after {
  opacity: var(--local-opacity, 0);
}

.checkbox:not(.switch):checked,
.radio:not(.switch):checked {
  --local-opacity: 1;
}

.checkbox + label,
.radio + label {
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: 4px;
}

.checkbox:not(.switch) {
  border-radius: 7px;
}

.checkbox:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--black);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 2px;
  transform: rotate(var(--local-rotation, 20deg));
}

.checkbox:not(.switch):checked {
  --local-rotation: 43deg;
}

.checkbox.switch {
  width: 38px;
  border-radius: 11px;
}

.checkbox.switch:after {
  left: 2px;
  top: 2px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: var(--ab, var(--active-inner));
  transform: translateX(var(--x, 0));
}

.checkbox.switch:checked {
  background: none;
  --ab: var(--active);
  --x: 17px;
}

.checkbox.switch:disabled:not(:checked):after {
  opacity: 0.6;
}

.radio {
  border-radius: 50%;
}

.radio:after {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: var(--local-bg-color);
  opacity: 0;
  transform: scale(var(--scale));
}

.radio:disabled:checked:after {
  background: var(--disabled-inner);
}

.radio:checked {
  --scale: 0.8;
}

.radio-black,
.switch-black,
.checkbox-black {
  --active: var(--black);
  --focus: var(--blackSecondary);
  --border: var(--blackSecondary);
  --border-hover: var(--black);
}

.radio-white,
.switch-white,
.checkbox-white {
  --active: var(--white);
  --focus: var(--whiteSecondary);
  --border: var(--whiteSecondary);
  --border-hover: var(--white);
}

.radio-cyan,
.switch-cyan,
.checkbox-cyan {
  --active: var(--cyan);
  --focus: var(--cyanSecondary);
  --border: var(--cyanSecondary);
  --border-hover: var(--cyan);
}

.radio-green,
.switch-green,
.checkbox-green {
  --active: var(--green);
  --focus: var(--greenSecondary);
  --border: var(--greenSecondary);
  --border-hover: var(--green);
}

.radio-orange,
.switch-orange,
.checkbox-orange {
  --active: var(--orange);
  --focus: var(--orangeSecondary);
  --border: var(--orangeSecondary);
  --border-hover: var(--orange);
}

.radio-pink,
.switch-pink,
.checkbox-pink {
  --active: var(--pink);
  --focus: var(--pinkSecondary);
  --border: var(--pinkSecondary);
  --border-hover: var(--pink);
}

.radio-purple,
.switch-purple,
.checkbox-purple {
  --active: var(--purple);
  --focus: var(--purpleSecondary);
  --border: var(--purpleSecondary);
  --border-hover: var(--purple);
}

.radio-red,
.switch-red,
.checkbox-red {
  --active: var(--red);
  --focus: var(--redSecondary);
  --border: var(--redSecondary);
  --border-hover: var(--red);
}

.radio-yellow,
.switch-yellow,
.checkbox-yellow {
  --active: var(--yellow);
  --focus: var(--yellowSecondary);
  --border: var(--yellowSecondary);
  --border-hover: var(--yellow);
}
