.list {
  list-style: none;
}

.list-unordered li::before {
  content: "—";
  padding-right: 10px;
}

.list-ordered {
  counter-reset: li;
}

.list-ordered-lower-alpha {
  --type: lower-alpha;
}

.list-ordered-upper-alpha {
  --type: upper-alpha;
}

.list-ordered-lower-roman {
  --type: lower-roman;
}

.list-ordered-upper-roman {
  --type: upper-roman;
}

.list-ordered-decimal {
  --type: decimal;
}

.list-ordered li {
  counter-increment: li;
  margin-left: -30px;
}

.list-ordered li::before {
  content: counter(li, var(--type, decimal))'.';
  display: inline-block;
  margin-left: 15px;
  padding-right: 5px;
  text-align: right;
  width: 30px;
  color: var(--accentColor);
}

@each $color in black, blackSecondary, white, cyan, green, orange, pink, purple, red, yellow {
  .list-$(color).list-unordered li::before,
  .list-$(color).list-ordered li::before {
    color: var(--$(color));
  }
}

.list-none {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}